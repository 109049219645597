/* eslint-disable react-hooks/exhaustive-deps */

import React from "react"
import { css } from "@emotion/core"
import { useCountUp } from "react-countup"

import Layout from "../components/layout"
import Head from "../components/head"
import PageHeading from "../components/pageHeading"
import Text from "../components/Text"
import Markdown from "../components/Markdown"

import fundsRaised from "../cms/funds-raised"

export default () => {
  const total = fundsRaised.donations.map(x => x.amount).reduce((accumulator, currentValue) => accumulator + currentValue)
  const { countUp } = useCountUp({ end: total, duration: 3 })

  const items = fundsRaised.donations

  return (
    <Layout>
      <Head title="Funds Raised" />
  
      <PageHeading text={"Funds Raised from Performances"} centered />

      <div
        css={css`
          max-width: 30rem;
          margin: 0 auto;
        `}
      >
        <Markdown
          raw={fundsRaised.text}
          css={css`
            margin-bottom: 2rem;
          `}
        />

        <Text
          style={css`
            font-weight: 600;
          `}
          centered
        >
          {"Donations as of " + new Date(fundsRaised.last_updated).toLocaleString("en-US", {
            timeZone: "America/New_York",
            year: "numeric",
            month: "long",
            day: "numeric"
          })}
        </Text>
    
        <div
          css={css`
            display: grid;
            grid-template-columns: 1fr auto;
            max-width: 30rem;
            grid-column-gap: 1rem;
            grid-row-gap: 1rem;
          `}
        >
          <React.Fragment>
            <Organization text="Total" isTotal={true} />
            <Amount text={countUp} isTotal={true} />
          </React.Fragment>

          {items.map(data => (
            <React.Fragment key={data.organization} >
              <Organization text={data.organization} />
              <Amount text={data.amount} />
            </React.Fragment>
          ))}
        </div>
      </div>
    </Layout>
  )
}

const Organization = props => (
  <Text
    style={`
      font-size: 1.2rem;
      ${!props.isTotal ? "color: var(--text-light-gray);" : ""}
      margin-bottom: 0;
      font-weight: ${props.isTotal ? "700" : "400"};
    `}
  >
    {props.text}
  </Text>
)

const Amount = props => (
  <p
    css={css`
      font-size: 1.2rem;
      justify-self: end;
      margin-bottom: 0;
      font-weight: ${props.isTotal ? "700" : "400"};
    `}
  >
    ${props.text}
  </p>
)