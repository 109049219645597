import React from "react"
import { css } from "@emotion/core"

export default props => {
  const childrenIsString = typeof props.children === "string"

  let children = props.children

  if(childrenIsString) {
    children = children.replace(/\*(.*?)\*/g, "<strong>$1</strong>")


    // "hyper" = hyperlink

    const hyperPattern = /\[(?<text>.+?)\]\((?<link>.+?)\)/

    while(hyperPattern.exec(children) !== null) {
      const { text, link } = hyperPattern.exec(children).groups

      let processedLink = link

      const isEmail = processedLink.includes("@")
      const isInternal = processedLink.startsWith("/")
      const hasHttp = processedLink.startsWith("http")

      if(isEmail) {
        processedLink = "mailto:" + link
      }

      if(!isEmail && !isInternal && !hasHttp) {
        processedLink = "http://" + link
      }

      children = children.replace(`[${text}](${link})`, `<a href="${processedLink}" style="color: var(--text-green)">${text}</a>`)
    }
  }

  return (
    <p
      css={css`
        white-space: pre-wrap;
        font-size: 1.1rem;
        line-height: 1.5;
        margin-bottom: 2rem;
        ${props.centered ? "text-align: center;" : ""}
        ${props.bold ? "font-weight: 600;" : ""}

        * {
          font-size: inherit;
        }

        strong {
          font-weight: 700;
        }

        ${props.style}
      `}
      dangerouslySetInnerHTML={{
        __html: children
      }}
    />
  )
}
