import React from "react"
import { css } from "@emotion/core"

export default props => (
  <h2
    css={css`
      font-size: 2rem;
      font-weight: 800;
      color: var(--text-black);
      margin-bottom: 2rem;
      ${props.centered ? "text-align: center;" : ""}
    `}
  >
    {props.text}
  </h2>
)